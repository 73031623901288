import { gsap } from 'gsap';

export function initBlockContent() {
	console.log('%cSTART:%c initBlockContent()', 'color:green', 'color:teal');
	let contentBlocks = document.querySelectorAll('section.block.content.expandable-content');

	contentBlocks.forEach((contentBlock, i) => {
		let contentInner = contentBlock.querySelector('.content-block-body-inner');
		let expanderLink = document.createElement('button');
		expanderLink.innerHTML = 'Show More';
		expanderLink.classList.add('button-plus');
		expanderLink.classList.add('button-expander');
		let contentBody = contentBlock.querySelector('.content-block-body');

		expanderLink.addEventListener('click', function () {
			contentBody.classList.add('content-expand'); // This shrinks the semi-transparent cover
			contentBody.style.height = contentBody.offsetHeight + 'px';
			contentBody.style.maxHeight = 'none';
			gsap.to(contentBody, {
				height: contentBlock.querySelector('.content-block-body-inner').offsetHeight + 1,
				duration: 0.4,
				onComplete: () => {
					contentBody.style.height = 'auto';
				},
			});
			gsap.to(expanderLink, { height: 0, duration: 0.2 });
		});

		contentBlock.appendChild(expanderLink);
	});
}
