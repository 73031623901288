import Swiper from 'swiper';
import { Navigation, Pagination, Manipulation } from 'swiper/modules';

export function initCardsFromContent() {
	console.log('%cSTART:%c initBlockContent()', 'color:green', 'color:teal');
	document.querySelectorAll('.cards-from-content').forEach((cardsFromContentSection) => {
		const gridToSlider = cardsFromContentSection.querySelector('[data-slider]');
		const slider = gridToSlider ? initSlider(gridToSlider) : false;
		createFilters(cardsFromContentSection, slider);

		setHeadings(cardsFromContentSection, slider);
	});
}
function initSlider(cardGrid) {
	console.log('%cSTART:%c initSlider()', 'color:green', 'color:teal');

	if (window.innerWidth < 600) return false;
	const cards = cardGrid.querySelectorAll(':scope > *');
	if (!cards) return;
	const cardSection = cardGrid.closest('.cards-from-content');
	if (!cardSection) return;

	const swiperContainer = document.createElement('div');
	swiperContainer.classList.add('swiper');
	const swiperWrapper = document.createElement('div');
	swiperWrapper.classList.add('swiper-wrapper');

	cards.forEach((card) => {
		card.classList.add('swiper-slide');
		swiperWrapper.append(card);
	});
	swiperContainer.append(swiperWrapper);
	const swiperPagination = document.createElement('div');
	swiperPagination.classList.add('swiper-pagination');

	const swiperButtonPrev = document.createElement('div');
	swiperButtonPrev.classList.add('swiper-button-prev');
	const swiperButtonNext = document.createElement('div');
	swiperButtonNext.classList.add('swiper-button-next');
	swiperContainer.append(swiperPagination, swiperButtonPrev, swiperButtonNext);

	cardSection.append(swiperContainer);
	const slider = new Swiper(swiperContainer, {
		modules: [Navigation, Pagination, Manipulation],
		speed: 1000,
		spaceBetween: 16,
		autoplay: {
			delay: 4500,
			disableOnInteraction: true,
		},
		pagination: {
			el: '.swiper-pagination',
		},
		navigation: {
			enabled: false,
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			600: {
				slidesPerView: 2,
			},
			1200: {
				slidesPerView: 3,
				navigation: {
					enabled: true,
				},
			},
		},
	});
	return slider;
}

function createFilters(cardsFromContentSection, slider) {
	const cardSection = cardsFromContentSection.querySelector('.pcm-cards');

	const cards = slider ? slider.slides : cardSection.querySelectorAll(':scope > *');

	if (!cards) return;
	let filterValues = [];

	cards.forEach((card) => {
		// Split the dataset filters, filter out blank strings, and then add to the array
		card.dataset.card = '';
		filterValues.push(...card.dataset.filters.split(',').filter((filter) => filter.trim() !== ''));
	});
	filterValues = [...new Set(filterValues)];

	if (!filterValues.length) return;
	const filterContainer = document.createElement('div');
	filterContainer.classList.add('filter-container');
	filterContainer.dataset.filterContainer = '';
	const filterInstructions = document.createElement('div');
	filterInstructions.classList.add('filter-instructions');
	filterInstructions.textContent = 'If you would like to filter results, click on one of the topic buttons below.';
	filterContainer.append(filterInstructions);

	filterValues = filterValues.map((filterValue) => {
		return { slug: filterValue, text: filterValue, selected: false };
	});

	filterValues.unshift({ slug: 'card', text: 'all', selected: true });

	filterValues.forEach((filter) => {
		const filterButton = document.createElement('button');
		filterButton.textContent = filter.text;
		filterButton.dataset.value = filter.slug;
		filterButton.dataset.selected = filter.selected;

		filterContainer.append(filterButton);
	});

	filterContainer.addEventListener('click', (e) => filterSlides(e, slider));

	cardsFromContentSection.insertBefore(filterContainer, cardSection);
}

function cardsPerRow() {
	return window.innerWidth < 1200 ? 2 : 3;
}
function unsetHeadingHeights(headings) {
	headings.forEach((heading) => {
		heading.style.minHeight = '';
	});
}
function setHeadings(cardsFromContentSection, slider) {
	if (window.innerWidth < 600) return;

	const cards = slider ? slider.slides : cardsFromContentSection.querySelectorAll('.pcm-card');
	const headings = Array.from(cards).map((card) => card.querySelector('.card-heading'));

	if (!cards) return;
	if (slider) {
		setHeadingHeightsInSlider(headings);
		return;
	}
	setHeadingHeightsByRow(headings, cardsPerRow());
}
function setHeadingHeightsInSlider(headings) {
	const headingHeight = Array.from(headings).reduce((maxHeight, heading) => {
		return Math.max(maxHeight, heading.offsetHeight);
	}, 0);
	headings.forEach((heading) => {
		heading.style.minHeight = `${headingHeight}px`;
	});
}
function setHeadingHeightsByRow(headings, cardsPerRow) {
	console.log('setHeadingHeightsByRow');
	for (let i = 0; i < headings.length; i += cardsPerRow) {
		let rowHeadings = Array.from(headings).slice(i, i + cardsPerRow);
		let tallestHeight = rowHeadings.reduce((maxHeight, heading) => {
			return Math.max(maxHeight, heading.offsetHeight);
		}, 0);

		rowHeadings.forEach((heading) => {
			heading.style.minHeight = `${tallestHeight}px`;
		});
	}
}

function filterSlides(e, slider) {
	if (e.target.tagName !== 'BUTTON') return;

	const cardSection = e.target.closest('section.cards-from-content');
	const filterContainer = cardSection.querySelector('[data-filter-container]');
	const selectedFilter = e.target;
	let dataAtt = 'data-card';

	if (selectedFilter.dataset.selected === 'true') {
		selectedFilter.dataset.selected = false;
	} else {
		filterContainer.querySelectorAll('button[data-selected]').forEach((filterButton) => {
			filterButton.dataset.selected = false;
		});
		selectedFilter.dataset.selected = true;
		dataAtt = `data-${selectedFilter.dataset.value}`;
	}

	const cards = cardSection.querySelectorAll(`[data-card]`);

	cards.forEach((card) => {
		card.classList.toggle('hide', !card.hasAttribute(dataAtt));
	});

	if (slider) {
		slider.updateSlides();
		slider.setProgress(0, 500); // Scroll to the start of the slider in .5 seconds
	}
}

const cardContainers = document.querySelectorAll('.cards-from-content');

const debouncedResizeCallback = debounce((entries) => {
	for (let entry of entries) {
		const cardContainer = entry.target;
		const headings = cardContainer.querySelectorAll('.card-heading');
		console.log('debouncedResizeCallback');
		// Check if the container is a slider
		const isSlider = cardContainer.querySelector('[data-slider]');
		unsetHeadingHeights(headings);
		if (isSlider) {
			setHeadingHeightsInSlider(headings);
		} else {
			setHeadingHeightsByRow(headings, cardsPerRow());
		}
	}
}, 250);

const resizeObserver = new ResizeObserver(debouncedResizeCallback);

cardContainers.forEach((cardContainer) => {
	resizeObserver.observe(cardContainer);
});

function debounce(func, wait) {
	let timeout;
	return function (...args) {
		const later = () => {
			clearTimeout(timeout);
			func.apply(this, args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}
