import './vendor/jquery-ui.min.js';
export function initAccordion() {
	console.log('%cSTART:%c initAccordion()', 'color:green', 'color:teal');
	jQuery('.accordion-items > div').accordion({
		active: false,
		collapsible: true,
		header: '.accordion-header',
		heightStyle: 'content',
		icons: { 'header': 'ui-icon-plus', 'activeHeader': 'ui-icon-minus' },
	});
}
