import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function initStickySidebarMenu() {
	console.log('%cSTART:%c stickySidebarMenu()', 'color:green', 'color:teal');
	gsap.registerPlugin(ScrollTrigger);

	// Combining these into a single variable causes issues for some reason.
	const sidebarHeight = gsap.getProperty('#sticky-sidebar', 'height');
	const spaceBelowSticky = 200;
	const sidebarOffset = sidebarHeight + spaceBelowSticky;

	ScrollTrigger.create({
		trigger: '#secondary',
		end: `bottom =+${sidebarOffset}`,
		pin: '#sticky-sidebar',
		pinSpacing: false,
		// markers: true,
	});
}

function handleParentItemClick(e) {
	console.log(e.target);
	e.target.closest('li.menu-item').classList.toggle('menu-collapsed');
}

function initMenuTree() {
	console.log('%cSTART:%c initMenuTree()', 'color:green', 'color:teal');
	if (!(nav = document.getElementById('page-sidebar-nav'))) return;

	const parentItems = nav.querySelectorAll('.sub-menu .menu-item-has-children');

	parentItems.forEach((parentItem) => {
		console.log(parentItem);
		parentItem.classList.add('menu-collapsed');

		const menuIcon = document.createElement('span');
		menuIcon.classList.add('menu-icon');
		console.log(menuIcon);
		menuIcon.addEventListener('click', handleParentItemClick);

		parentItem.appendChild(menuIcon);
	});
}

function initSidebar() {
	stickySidebarMenu();
	// initMenuTree();
}
