import '../scss/style.scss';
// import '../scss/editor-style.scss';

import { initAccordion } from './init-accordion';
import { initCardsFromContent } from './block-cards-from-content';
import { initBlockContent } from './block-content';
import { initFeatherLight } from './init-featherlight';
import { initStickySidebarMenu } from './init-sidebar';

initAccordion();
initBlockContent();
initFeatherLight();
initStickySidebarMenu();
initCardsFromContent();
